import React, { ReactElement } from 'react'
import './Home.css'
import {
  FaCamera,
  FaCloud,
  FaDiscord,
  FaHome,
  FaTwitter,
  FaTelegram,
  FaInstagram,
  FaTumblr,
  FaTiktok,
} from 'react-icons/fa'
import { SiFuraffinity } from 'react-icons/si'
import headshot from '../images/headshot.jpg'
import SocialLink, { SocialLinkProps } from '../components/SocialLink'
import { BsChatHeart } from 'react-icons/bs'
import { BiLogoDiscord } from 'react-icons/bi'
import Page from '../components/Page'
import CaptionedImage from '../components/CaptionedImage'
const socials: SocialLinkProps[] = [
  {
    siteName: 'Twitter',
    handle: '@nicksfurry',
    link: 'https://twitter.com/nicksfurry',
    children: <FaTwitter />,
  },
  {
    siteName: 'Telegram',
    handle: '@nicksfurry',
    link: 'https://t.me/nicksfurry',
    children: <FaTelegram />,
  },
  {
    siteName: 'Instagram',
    handle: '@nicksfurry',
    link: 'https://instagram.com/nicksfurry',
    children: <FaInstagram />,
  },
  {
    siteName: 'FurAffinity',
    handle: '@nicksfurry',
    link: 'https://www.furaffinity.net/user/nicksfurry',
    children: <SiFuraffinity />,
  },
  {
    siteName: 'BlueSky',
    handle: 'sparkledog.space',
    link: 'https://bsky.app/profile/sparkledog.space',
    children: <FaCloud />,
  },
  {
    siteName: 'Tumblr',
    handle: '@nicksfurry',
    link: 'https://www.tumblr.com/nicksfurry',
    children: <FaTumblr />,
  },
  {
    siteName: 'ToyHouse',
    handle: '@nicksfurry',
    link: 'https://toyhou.se/nicksfurry',
    children: <FaHome />,
  },
  {
    siteName: 'FurTrack',
    handle: 'atlas_(sparkledog)',
    link: 'https://www.furtrack.com/index/character:atlas_(sparkledog)',
    children: <FaCamera />,
  },
  {
    siteName: 'TikTok',
    handle: '@nicksfurry',
    link: 'https://tiktok.com/@nicksfurry',
    children: <FaTiktok />,
  },
  {
    siteName: 'Discord',
    handle: 'nicksfurry',
    link: 'https://discordapp.com/users/nicksfurry',
    children: <FaDiscord />,
  },
  {
    siteName: 'Telegram Channel',
    handle: 'nicksfurryart',
    link: 'https://t.me/nicksfurryart',
    children: <BsChatHeart />,
  },
  {
    siteName: 'Discord Server',
    handle: 'Sparkledog Space',
    link: 'https://discord.gg/P7GErANYrw',
    children: <BiLogoDiscord />,
  },
]

const makeSocialLinks = (): ReactElement[] => {
  const socialLinks: ReactElement[] = []
  for (const site of socials) {
    socialLinks.push(<SocialLink {...site} key={site.siteName} />)
  }
  return socialLinks
}

const Home = () => {
  return (
    <Page className="Home">
      <div className="HomeContent">
        <div className="Greeting">
          <h1>Atlas Sparkledog</h1>
          <CaptionedImage
            src={headshot}
            alt="badge"
            credits={{
              artist: "TheNamelessHare",
              artistLink: "https://twitter.com/thenamelesshare/"
            }}
          />
          <p>
            Hi I'm Atlas! I'm an artist and fursuiter! Catch me on one of my
            socials!
          </p>
        </div>
        <div className="HomeLinks">{makeSocialLinks()}</div>
      </div>
    </Page>
  )
}

export default Home
