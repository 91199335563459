import React, { ReactElement } from 'react'
import './Dropdown.css'

export interface NavLink {
    text: string
    link: string
}

interface DropdownProps {
    navLinks: NavLink[]
    visible: boolean
}

const makeDropdownLinks = (navLinks: NavLink[]): ReactElement[] => {
    const dropdownLinks: ReactElement[] = []
    for (const link of navLinks) {
        dropdownLinks.push(
            <a className="DropdownLink" href={link.link}>
                <div className="DropdownLinkText">{link.text}</div>
            </a>,
        )
    }
    return dropdownLinks
}

const Dropdown = (props: DropdownProps) => {
    return (
        <div className="Dropdown">
            {props.visible ? makeDropdownLinks(props.navLinks) : <div />}
        </div>
    )
}

export default Dropdown
