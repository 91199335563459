import React from 'react'
import './CaptionedImage.css'

interface ArtistCredits {
    artist: string
    artistLink: string
}

interface CaptionedImageProps {
    src: string
    alt: string
    title?: string
    credits?: ArtistCredits
}

const CaptionedImage = (props: CaptionedImageProps) => {
    return (
        <div className="CaptionedImage">
            <img alt={props.alt} src={props.src} />
            {props.title && <p>{props.title}</p>}
            {props.credits && (
                <p>
                    Artist: <a href={props.credits.artistLink}>{props.credits.artist}</a>
                </p>
            )}
        </div>
    )
}

export default CaptionedImage
