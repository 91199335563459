import React, { ReactNode } from 'react'
import './SocialLink.css'

export interface SocialLinkProps {
  siteName: string
  handle: string
  link: string
  children: ReactNode
}

const SocialLink = (props: SocialLinkProps) => {
  return (
    <a className="SocialLink" href={props.link}>
      <div className="SocialLinkIcon">{props.children}</div>
      <p className="SocialLinkText">{props.handle}</p>
      <p className="SocialLinkSite">
        <b>{props.siteName}</b>
      </p>
    </a>
  )
}

export default SocialLink
