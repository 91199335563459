import React from 'react'
import './About.css'
import Page from '../components/Page'
import Halloween from '../images/halloween.jpg'
import Skateboard from '../images/skateboard.jpg'

const About = () => {
  return (
    <Page>
      <div className="About">
        <img alt="Partial fursuiter holding skateboard" src={Skateboard} />
        <p>Atlas</p>
        <p>Nonbinary | He/They</p>
        <p>New York City Dog</p>
        <p>
          Fursuit made by{' '}
          <a href="https://www.wilddogworks.com/">WildDogWorks</a>!
        </p>
        <img alt="Shadowed fursuiter with glowing green eyes" src={Halloween} />
      </div>
    </Page>
  )
}

export default About
