import React from 'react'
import './Portfolio.css'
import Page from '../components/Page'
import Dj from "../images/dj.jpg"
import FloatOn from "../images/floatOn.jpg"
import Slice from "../images/slice.jpg"
import Portal2 from "../images/portal2.jpg"

const Portfolio = () => {
  return (
    <Page>
      <div className="Portfolio">
        <p>
          Here are some of the things I've made! To see other stuff from me
          check out my Twitter or Telegram Channel!
        </p>
        <img alt="Two furries floating in innertubes on a pool from above" src={FloatOn}/>
        <img alt="A furry dj-ing in front of a city skyline" src={Dj}/>
        <img alt="A furry waiting in front of a pizza restaurant" src={Slice}/>
        <img alt="A raccoon furry falling in an infinite loop through portals from the Portal 2 videogame" src={Portal2}/>
      </div>
    </Page>
  )
}

export default Portfolio
