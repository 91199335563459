import React, { ReactNode } from 'react'
import './Page.css'
import Header from './Header'

interface PageProps {
  children?: ReactNode
  className?: string
}

const makeClassName = (className?: string): string => {
  let pageClassName = 'Page'
  if (className != null) {
    pageClassName += ' ' + className
  }
  return pageClassName
}

const Page = (props: PageProps) => {
  return (
    <div className={makeClassName(props.className)}>
      <Header />
      {props?.children}
    </div>
  )
}

export default Page
