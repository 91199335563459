import React from 'react'
import Page from '../components/Page'
import RefAtlas from '../images/ref-atlas.jpg'
import RefKai from '../images/ref-kai.jpg'
import RefNick from '../images/ref-nick.jpg'
import './Reference.css'

const Reference = () => {
  return (
    <Page className="Reference">
      <div className="ReferenceContainer">
        <h2>Atlas</h2>
        <img alt="Atlas Ref" src={RefAtlas} />
        <h2>Kai</h2>
        <img alt="Kai Ref" src={RefKai} />
        <h2>Nick</h2>
        <img alt="Nick Ref" src={RefNick} />
      </div>
    </Page>
  )
}

export default Reference
