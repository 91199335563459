import React, { useState } from 'react'
import './Header.css'
import { IoMenu } from 'react-icons/io5'
import Dropdown, { NavLink } from './Dropdown'

const navLinks: NavLink[] = [{
  text: 'About',
  link: '/about',
}, {
  text: 'Portfolio',
  link: '/portfolio',
}, {
  text: 'Reference',
  link: '/reference'
}]


const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  return (
    <>
    <div className="Header">
      <div className="HeaderComponents">
        <div className="Logo">
          <h1>
            <a href="/">Sparkledog&nbsp;Space</a>
          </h1>
        </div>
        <a className="HeaderNavLink" href="/about">
          About
        </a>
        <a className="HeaderNavLink" href="/portfolio">
          Portfolio
        </a>
        <a className="HeaderNavLink" href="/reference">
          Reference
        </a>
        <div onClick={() => setDropdownVisible(!dropdownVisible)} className="HamburgerMenu">
          <IoMenu />
        </div>
      </div>
    </div>
    <Dropdown visible={dropdownVisible} navLinks={navLinks} />
    </>
  )
}

export default Header
